import React from "react";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
// import Benefits from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import GetStartedLight from "components/cta/GetStartedLight";
import Footer from "components/footers/FiveColumnWithBackground.js";
import macHeroScreenshotImageSrc from "images/steps.png";
// import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import dursunImage from "images/dursun.png";


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-secondary-400`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={false} />
      <Features
        subheading={<Subheading>Features & benefits</Subheading>}
        heading={
          <>
            Empower <HighlightedText>your sales team.</HighlightedText>
          </>
        }
        // TODO
        description=""
      />
      {/* <Benefits /> */}

      {/* 
      <MainFeature
        subheading={<Subheading>BENEFITS</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        description="THIS"
      /> */}
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Seamless Integration <HighlightedText>With Your Workflow.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      {/* <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        description="Accessible and User-Friendly Platform"
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Mobile-Friendly",
            description: "Use our app on the go to access your speech insights anytime.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          },
          
        ]}
        features2={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Mobile-Friendly",
            description: "Use our app on the go to access your speech insights anytime.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          },
        
        ]} */}

      {/* /> */}
      {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
          }
        ]}
      /> */}
      <GetStartedLight />
      

      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </> 
        }
        description="Speakpro is a powerful tool that enhances communication skills, making it a favorite among MBA students preparing for final exams, as well as sales representatives and marketing leaders. It empowers users to communicate more effectively in both their professional and personal lives. Users are consistently amazed at how easy and useful Speakpro is, transforming their communication abilities effortlessly."
        // description="We have been incredibly impressed with the results. The tool has helped our team communicate more effectively and confidently. Our presentations are more engaging, and our meetings are more productive. We highly recommend it to anyone looking to enhance their communication skills."
        testimonials={[
          {
            // stars: 5,
            profileImageSrc: dursunImage,
            heading: "Exceptional Onboarding and Training Experience",
            quote:
            "Our onboarding process has been revolutionized thanks to this interactive learning platform. New employees receive personalized feedback that accelerates their learning curve, allowing them to integrate seamlessly into the team and perform effectively from the start. The empowering vocabulary training and real-time analysis have made a noticeable difference in their communication skills. This tool not only improves individual performance but also boosts overall team efficiency. A must-have for any business looking to enhance their onboarding experience!",
            customerName: "Dursun Yigit",
            customerTitle: "Hauptstadt.TV GmbH Owner"
          },
          {
            // stars: 5,
            profileImageSrc: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Professional Review",
            quote:
              "We've been using this speech improvement tool to boost our communication skills. The AI feedback during presentations and meetings has been really useful. It's helped us improve both our sales performance and our internal communication.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            // stars: 5,
            profileImageSrc: "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Outstanding User Experience",
            quote:
              "Great tool! Our team's communication skills have improved a lot. We're better at presentations and more effective in meetings. Highly recommend it.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
          
        ]}
      />
      <FAQ
        id="faq-section"
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        description=""
        faqs={[
          {
            question: "What is the main benefit of using this speech improvement tool?",
            answer: "Our tool transforms your communication skills, empowering your sales team to speak with confidence, sell with impact, and close more deals effectively."
          },
          {
            question: "How does the personalized feedback work?",
            answer: "Our AI engine analyzes your recorded exercises, calls, presentations, or meetings to provide tailored insights on your speaking style, tonality, and body language, helping you improve in real-time. During your speech or presentation, our AI engine identifies areas for improvement in your delivery and provides instant feedback, so you can make adjustments on the spot."
          },
          // {
          //   question: "Is the platform user-friendly?",
          //   answer: "Absolutely! Our intuitive interface is designed to be easy to navigate, ensuring that you can get started quickly and efficiently."
          // },
          // {
          //   question: "Can I use this tool on mobile devices?",
          //   answer: "Yes, our platform is mobile-friendly, allowing you to access your speech insights anytime, anywhere using our app."
          // },
          // {
          //   question: "How does the real-time analysis feature work?",
          //   answer: ""
          // },
          {
            question: "Is it possible to track my progress over time?",
            answer: "Yes, you can track your progress through detailed reports and analytics, helping you see how your communication skills improve over time."
          },
          // {
          //   question: "How does the seamless integration feature enhance my workflow?",
          //   answer: "Our tool integrates smoothly with your existing sales workflow, ensuring maximum impact without disrupting your current processes."
          // },
          // {
          //   question: "What kind of support is available if I need help?",
          //   answer: "Our dedicated support team is available to assist you every step of the way, providing expert guidance and solutions whenever you need them."
          // },
          // {
          //   question: "Is the platform cloud-based?",
          //   answer: "Yes, our cloud-based platform ensures that you can seamlessly integrate it into your existing workflows and access it from any device."
          // },
          {
            question: "How do I get started with this tool?",
            answer: "Simply sign up on our website, and you'll be guided through the setup process. Our user-friendly platform makes it easy to start transforming your communication skills immediately."
          },
          {
            question: "How can this tool assist in onboarding new employees?",
            answer: "Our tool provides new employees with personalized feedback and training to quickly improve their communication skills, helping them integrate seamlessly into the team and perform effectively from the start."
          },
          {
            question: "How does the tool help in training for empowering speech?",
            answer: "The tool offers specialized training modules focused on empowering speech, including exercises on speaking confidently, engaging the audience, and handling objections, all supported by real-time feedback and analysis."
          }
        ]}

      />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
}
